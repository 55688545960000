.fullScreenLoader {
    height: 100vh;
    width: 100vw;
    position:fixed;
    z-index: 999;
    background-color: rgba(255,255,255,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
}