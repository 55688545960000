.budget-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
    .budget-header .budgetsDescription{
        display: flex;
        align-items: center;
        text-align: left;
        font: normal normal bold 22px/30px Open Sans;
        color: #9F9F9F;
        margin: 30px 0px 20px 0px;
    }
  
    .budget-header .actions{
        display: flex;
        flex-direction: row;
        padding: 0 20px;
    }
        .budget-header .actions .circle-background{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FF8686;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin: 30px 0px 20px 0px;
        }
        .budget-header .actions .circle-background:hover{
            cursor: pointer;
            background-color: #EB7C7C;
        }
        .budget-header .actions .circle-background .deleteBudget-svg{
            width: 25px;
            height: 25px;
            fill: white;
        }



.budget-lenght{
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 22px/30px Open Sans;
    color: #9F9F9F;
    padding: 20px;
}

.table-budget{
    display: flex;
    flex-grow: 1; 
}
/************************* ok ************/