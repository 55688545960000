.combobox-container{
    position: relative;
    flex-grow: 1;
}



.combobox{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}



.combobox-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    color: #9F9F9F;
    background-color: #FFFFFF;
    padding: 15px;
    /* width: 100%; */
}
    .combobox-group .combobox-text{
        display: flex;
        align-items: center;
        flex-grow: 1;
        border: none;
        font: normal normal normal 14px/19px Open Sans;
        color:#9F9F9F;
    }
    .combobox-group .combobox-text:active,
    .combobox-group .combobox-text:focus-visible{
        outline-color: #575757;
    }
    .combobox-group .combobox-arrow{
        display: flex;
        align-items: center;
    }
    .combobox-group .combobox-arrow .svg{
        width: 19px;
        height: 19px;
        fill: #575757;
    }


.combobox-dropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 8px;
    border: 1px solid #575757;
    background-color: #FFFFFF;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: scroll;
    z-index: 1;
    top: 50px;
}
    .combobox-dropdown .combobox-option{
        padding: 7px 15px;
        display: flex;
        align-items: center;
        height: 30px;
    }
    .combobox-dropdown .combobox-option:hover{
        background-color: #EAEAEA;
        cursor: pointer;
    }



/*** RESIZES ***/
@media only screen and (min-width: 790px) {
    .combobox-container{
        flex-grow: 1;
        /* min-width: 27%; */
        display: flex;
    }
}

@media only screen and (min-width: 1430px) {
    .combobox-container{
        /* min-width: 24%;
        max-width: 24%; */
    }
}

@media only screen and (min-width: 1600px) {
    .combobox-container{
        /* min-width: 13.1%;
        max-width: 13.1%; */
    }
}

@media only screen and (min-width: 1800px) {
    .combobox-container{
        /* min-width: 5%;
        max-width: 7%; */
    }
}