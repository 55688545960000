.n4it-login {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.n4it-login-overlay{
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left:0;
}

.n4it-login-overlay img {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.n4it-login-overlay.yellow{
    background: transparent linear-gradient(180deg, #F5AB56 0%, #FFDB89 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 0.8;
}

.n4it-login > * { z-index: -1; }

.n4it-login-content{ width: 100%; z-index: 10; }

.home-items{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 90px;
}

.home-title{ margin-bottom: 1rem; }

.login-logo-product { height: 65px; margin-left:5px; }

.home-secondTitle{
    margin-bottom: 2rem;
    font: normal normal bold 110px/150px Open Sans;
    color: #FFFFFF;
}

.home-fullWidthHeight{ display: flex; }

.button-spaced{ margin-right: 30px; }

/****************** OK *******************/