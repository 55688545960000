.n4mp-table {
    /* position: relative; */
    padding: 20px 40px;
    min-width: fit-content;
}

.header-table{
    display: flex;
    flex-direction: row;
}




.n4mp-table-overlay{
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.n4mp-table-overlay .MuiCircularProgress-colorPrimary {
    color: #575757 !important;
}


.n4mp-table table {
    border-collapse: collapse;
    margin: 0;
    width: 100%;
}


.n4mp-table tr,
.n4mp-table td {
    border-bottom: 1px solid #EAEAEA;
    opacity: 1;
}


.n4mp-table-cell-align_center {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.n4mp-table-grow {
    flex-grow: 1;
}

.n4mp-table td.n4mp-table-cell-reset {
    border: none;
}






.n4mp-table-info {
    display: flex;
    justify-content: center;
    text-align: left;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #9F9F9F;
    opacity: 1;
    padding: 20px 10px;
}