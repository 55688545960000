.n4mp-table-footer{
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    text-align: left;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #9F9F9F;
    opacity: 1;
}

.n4mp-table-footer-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.n4mp-table-footer-area div {
    margin-left: 10px;
}

.n4mp-table-footer-area select {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
    padding: 5px 5px 5px 10px;
    margin-left: 10px;
    cursor: pointer;
}

.n4mp-table-footer-pagination{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}



.n4mp-square {
    width: 25px;
    height: 25px;
    background: #575757 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n4mp-square:hover {
    background: #272727 0% 0% no-repeat padding-box;
    
}

.n4mp-square.reversed svg {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.n4mp-square.disabled {
    background: #EAEAEA 0% 0% no-repeat padding-box;
}
