.n4mp-form{
 margin: 0.4rem;
}


.n4mp-form .label{
    font-weight: 600;
    color: #66667C;
}

.n4mp-form.horizontal{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}


.n4mp-form .error-message {
    color: #D8000C;
}
