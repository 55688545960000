.splash-bg {
    width: 100%;
    height: 100%;
    background-color: gainsboro;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.splash-spacer {
    flex-grow:1;
}


.splash-logo img {
    max-height: 60px;
}



img.splash-logo-product {
    max-height: 200px;
}
