.providersPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.providersPage .add-content{
    display: flex;
}

.providersPage .retailers{
    padding: 40px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    font: normal normal bold 22px/30px Open Sans;
    color: #575757;
}

.providersPage .add-button{
    display: flex;
    align-items: center;
    padding: 40px;
}

.cell-grow { flex-grow: 1; }



.n4mp-circle{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .n4mp-circle.light { background: #EAEAEA 0% 0% no-repeat padding-box; }
    .n4mp-circle.light:hover{ background: transparent linear-gradient(90deg, #F5AB56 0%, #FFDB89 100%) 0% 0% no-repeat padding-box; }

    .n4mp-circle.black{
        background: #575757 0% 0% no-repeat padding-box;
        fill: white;
    }
    .n4mp-circle.black:hover{ background: #272727 0% 0% no-repeat padding-box; }

    .n4mp-circle.red{
        background: #ff8686 0% 0% no-repeat padding-box;
        fill: white;
    }
    .n4mp-circle.red:hover{ background: #EB7C7C 0% 0% no-repeat padding-box; }


.smallSVG{
    display: flex;
    justify-content: center;
    width: 12px;
    height: 12px;
}



.running-status{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width:100%;
}
    .running-status .statusSVG{
        display: flex;
        justify-content: center;
        width: 25px;
        height: 25px;
    }
    .running-status .statusSVG.green{ fill: #91D391 }
    .running-status .statusSVG.yellow{ fill: #FFDB89  }
    .running-status .statusSVG.red{ fill: #FF8686 }


.header-center{
    background-color: red;
    justify-content: center;
}
/********************* ok ********************/





.providersPage .contentBox{
    width: 100%;
}