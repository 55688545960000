.popup-full-screen{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-full-screen .popup{
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    z-index: 100;
}
