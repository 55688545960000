.Subscription{
display: flex;
flex-direction: row;
justify-content: center;
}

.Subscription .invite, .Subscription .base, .Subscription .premium{
    display: flex;
}

.Subscription .invite-title , .Subscription .base-title, .Subscription .premium-title{
    padding: 40px;
    display: flex;
    align-items: center;
    font: normal normal bold 22px/30px Open Sans;
    color: #575757;
}

.error-message{
    color: brown;
    margin: 5px 0px;
}

.input-invitationToken{
    background:  #F7F7F7 0% 0% no-repeat padding-box;
}

.sub-btn-green{
    font: normal normal bold 14px/19px Open Sans !important;
    background: #91D391 0% 0% no-repeat padding-box !important;
    border-radius: 8px;
    height: 50px;
}
.sub-btn-green:hover{
    background: #87C387 0% 0% no-repeat padding-box !important;
}

.sub-btn-gold{
    font: normal normal bold 14px/19px Open Sans !important;
    background: #ffdb89 0% 0% no-repeat padding-box !important;
    border-radius: 8px;
    height: 50px;
}
.sub-btn-gold:hover{
    background: #f5ab56 0% 0% no-repeat padding-box !important;
}

.sub-btn-blue{
    font: normal normal bold 14px/19px Open Sans !important;
    background: #91ced3 0% 0% no-repeat padding-box !important;
    border-radius: 8px;
    height: 50px;
}
.sub-btn-blue:hover{
    background: #6ac4cc 0% 0% no-repeat padding-box !important;
}

.check-list-content{
display: flex;
flex-direction: column;
gap: 20px;
}

.check-list{
    display: flex;
    flex-direction: row;
}

.icon-chek{
    display: flex;
    align-items: center;
    width: 15px;
    /* height: 15px; */
    margin-right: 5px;
}

.subForm-contentbox{
    height: 100%;
    display:flex;
    flex-direction: column;

}

.subForm-container{
    padding: 50px;
    color: #575757;
    display: flex;
    flex-direction: column;
    flex-grow:1;
}

.subForm-container .subForm-title{
    font-size: 22px;
    font-weight: 700;
}

.separatorSubForms{
    width: 3.75rem;
}

.input-invite{
    height: 50px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    padding-left: 15px;
}

.sub-form{
 flex-grow: 1;
}

.Subscription .contentBox-White{
    flex-grow: 1;
}

@media only screen and (min-width: 375px) {
 
    .Subscription{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .input-invite{
        width: 100%;
        box-sizing: border-box;
    }

    .standard-layout .main-standard {
        padding: 0 20px 70px 20px;
    }

}

@media only screen and (min-width: 500px) {
 
    .Subscription{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .input-invite{
        width: 100%;
        box-sizing: border-box;
    }

    .standard-layout .main-standard {
        padding: 0 40px 70px 40px;
    }

}

@media only screen and (min-width: 800px) {
 
    .Subscription{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .input-invite{
        width: 100%;
        box-sizing: border-box;
    }

    .standard-layout .main-standard {
        padding: 0 40px 70px 40px;
    }

}

