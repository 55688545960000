.header{
    display: flex;
    height: 115px;
    border-bottom: solid 3px #EAEAEA;
    align-items: center;
    margin-bottom: 40px;
}

.header .header-title{
    flex-grow: 1;
    font: normal normal bold 27px/37px 'Open Sans';
    color: #575757;
}

.header .header-user:hover{
    cursor: pointer;
    background: #A2A2A2  0% 0% no-repeat padding-box;
}

.n4mp-circle-avatar{
    background: #EAEAEA   0% 0% no-repeat padding-box;
    border-radius: 50%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiList-padding { padding: 0 !important; }

.userSVG{ display: flex; }

.userSVG:hover #user-solid{
    display: flex;
    fill: #EAEAEA ;
    color: #EAEAEA ;
}

/****************** OK *******************/