.searchcard {
    /* margin: 40px 25px 40px 0; */
}

.searchCard-content {
    display: flex;
    flex-direction: column;
    padding: 50px;
    flex-grow: 1;
}

.searchCard-header{
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    border-bottom: solid 1px #EAEAEA;
}

.searchCard-title{
    display: flex;
    flex-grow: 1;
    align-items: center;
    font: normal normal bold 22px/30px Open Sans;
    color: #575757;
}

.searchCard-buttonGroup{
    display: flex;
    flex-direction: row;
}

.renderSearchSelected{
    margin-top: 30px;
}

/****************** OK *******************/