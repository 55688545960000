.content-user{
    padding: 20px;
   
}

.user-header{
    display: flex;
    flex-direction: row;
}
    .user-header .user-header-svg{
        display: flex;
        align-items: center;
        background: #EAEAEA  0% 0% no-repeat padding-box;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

.user-info{
    margin-left: 5px;
    align-items: center;
}
    .user-info .user-name{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
        color: #575757;
    }
    .user-info .user-email{
        font: var(--unnamed-font-style-normal) normal normal var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
        color: #9F9F9F;
    }

.separator-user{
    border: solid 1px #EAEAEA;
    margin-top: 10px;
    width: 100%;
}

.options-info{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    padding: 10px;
    cursor: pointer;
    color: #9F9F9F;
    fill: #9F9F9F;
}
    .options-info .option-icon{
        width: 18px;
        height: 18px;
    }
    .options-info .option-text{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 14px/19px Open Sans;
        letter-spacing: 0px;
        margin-left: 15px;
    }
.options-info:hover{
    color: #575757;
    fill: #575757;
}

/****************** OK *******************/