.popup-container{ 
    padding: 50px;
    color: #575757;
}

.popup-container .popup-title{
    font-size: 22px;
    font-weight: 700;
 }

.popup-separator{
    border-bottom: solid 1px #EAEAEA;
    margin: 40px 0 30px 0;
}

.popup-container .popup-message{
    font: normal normal 600 14px/19px Open Sans;
    padding-bottom: 10px;
}

.popup-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.popup-button-separator{ width: 10px; }

.popup-div-button{ flex-grow: 1; }

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    border-radius: 15px;
}

/************** ok **********************/