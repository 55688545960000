.tyre-description-table-cell{
    display: flex;
    flex-direction: row;
}

.tyre-description-table-cell-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}


.tyre-description-table-cell-description{
    padding-left: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

}


.tyre-description-table-cell-title{
    text-align: left;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
}

.tyre-description-table-cell-properties {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

.tyre-description-table-cell-properties  div {
    margin-right: 5px;
}





.n4mp-circle.disabled {
    background: #EAEAEA 0% 0% no-repeat padding-box;
}


.n4mp-elipse {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEAEA 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    height: 13px;

    text-align: left;
    font: normal normal normal 11px/14px Open Sans;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
    padding: 3px 10px;
}

.n4mp-elipse svg {
    height: 12px;
    margin-right: 5px;
}
