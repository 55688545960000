.button{
    width: 150px;
    height: 50px;
    border-radius: 8px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #FFFFFF;
    text-align: left;
    font: normal normal bold 16px/22px Open Sans;
    letter-spacing: 0px;
    opacity: 1;
}

.button-Labels{
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.button-Small{
    
    width: fit-content;
    border: solid;
    border-radius: 30px;

    font-size: small;
    padding: 0.3rem 0.6rem;

    display: flex;
    justify-content: center;
    align-items: center;
}


.button-Gold{
    background: transparent linear-gradient(180deg, #F5AB56 0%, #FFDB89 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    border: 0px !important;
}

.button-Gold:hover{
    cursor: pointer;
    background: #FFDB89 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
}

.button-Gold:active{
    cursor: pointer;
    background: #FFDB89 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
}

.button-Secondary{
    border: 1px solid var(--unnamed-color-ffffff);
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.button-Secondary:hover{
    cursor: pointer;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ffffff);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-575757);
    text-align: left;
    font: normal normal bold 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
}

.button-Secondary:active{
    cursor: pointer;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ffffff);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    opacity: 1;
}

.button-Black{
    opacity: 1;
    width: 120px;
    height: 50px;
    cursor: pointer;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-9f9f9f);
    text-align: center;
    font: normal normal bold 14px/19px Open Sans;
    color: #9F9F9F;
    letter-spacing: 0px;
    border-radius: 23px;
}

.button-Black:hover{
    cursor: pointer;
    background: #575757 0% 0% no-repeat padding-box;
    color: white;
}

.btn-active.button-Black {
    cursor: pointer;
    background: #575757 0% 0% no-repeat padding-box;
    color: white;
}

.button-Green{
    background: var(--unnamed-color-91d391) 0% 0% no-repeat padding-box;
    background: #91D391 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    width: 140px;
    height: 50px;
    cursor: pointer;
}

.button-Second-Black{
    width: 160px;
    height: 50px;
    background: var(--unnamed-color-575757) 0% 0% no-repeat padding-box;
    background: #575757 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
}

.button-Second-Black:hover{
    background: #272727 0% 0% no-repeat padding-box;
}

.button-BorderGold{
    border: solid;
    color: var(--color-gold);
    margin: 5px;
    cursor: pointer;
}

.button-BorderGold:hover{
    cursor: pointer;
    background-color: var(--color-gold);
    border-color: var(--color-gold);
    color: white;
   
}

.button-BorderGold:active{
    cursor: pointer;
    background-color: var(--color-dark-blue);
    border-color: var(--color-dark-blue);
    color: white;
   
}