.menu{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.side-logo{
    display: flex;
    align-items: center;
    height: 160px;
    padding-left: 45px;
}

.sidebar-logo { height: 55px; }

.menu .top{ flex-grow: 1; }

.menu .bottom{
    display: flex;
    flex-direction: column;
}

.sidebar-btn{
    display: flex;
    align-items: center;
    padding: 25px 25px 25px 45px;
    color: #9F9F9F;
    fill: #9F9F9F;
    cursor: pointer;
}

.sidebar-btn-icon{
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
}

.sidebar-btn-text{
    margin: 0 10px;
    letter-spacing: var(--unnamed-character-spacing-0);
    font: normal normal 600 16px/22px Open Sans;
}

.sidebar-btn-active .sidebar-btn, .sidebar-btn:hover{
    background: transparent linear-gradient(90deg, #F5AB56 0%, #FFDB89 100%) 0% 0% no-repeat padding-box;
    color: white;
    fill: white;
}

.menu a, .menu a:hover, .menu a:visited { text-decoration: none; }

/*** RESIZES ***/
@media only screen and (max-width: 992px) {
    .side-logo{
        padding-left: 0px;
        justify-content: center;
    }
    .sidebar-logo{
        height: 20px;
        width: 40px;
    }

    .sidebar-btn{ padding: 22.5px; }
    
    .sidebar-btn-icon{
        align-items: unset;
        width: 25px;
        height: 25px;
        flex-grow: 1;
    }

    .sidebar-btn-text{ display: none; }
}
/****************** OK *******************/