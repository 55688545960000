.infoCard-container{
    background: #f7f7f7 0% 0% no-repeat padding-box !important;
    display: flex;
}

.infoCard-content{
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 40px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border-radius: 15px;
}

.icon{
    background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat padding-box;
    width: 89px;
    height: 89px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.info .info_number{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-22)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-open-sans);
    color: var(--unnamed-color-575757);
}

.info .info_text{
    font: normal normal 600 14px/19px Open Sans;
    color: #A2A2A2;
}

/****************** OK *******************/