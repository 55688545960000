.duplicate-instrument-main{
    width: 500px;
    height: 300px;
}

.popUp-children{
    justify-content: center;
    flex-direction: column;
    align-items: center;

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-22)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-575757);
    text-align: left;
    font: normal normal bold 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
}

.popUp-buttonsInline{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.scroll-box {
    overflow-y: auto;
    max-height: 50rem;
  }
  
  .scroll-box-side-padding {
    padding-left: 1.25rem;
    padding-right: 1.2rem;
  }
  
  .scroll-box-side-margin {
    margin: 0rem 0.5rem;
  }

  .test{
    border-radius: 50%;
  }