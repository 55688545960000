.mainPage{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.leftCard, .rightCard{
    display: flex;
    width: 50%;
}

.moreCard{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 50%;
}

.moreCard .subscriptionCard{
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
}

.moreCard .infoCard{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    height: 100%;
    flex-basis: 0;
}

.verticalSeparator{
    width: 50px;
    min-width: 50px;
}

.horizontalSeparator{
    height: 50px;
    min-height: 50px;
}

/*** RESIZES ***/
/* ok */
@media only screen and (min-width: 660px) {
    .mainPage { flex-direction: column; }

    .leftCard{
        width:100%;
        margin-bottom: 40px;
    }

    .rightCard { width: 100%; }

    .moreCard{ flex-direction: column; }

    .subscriptionCard{ margin-bottom: 40px; }

    .n4mp-iconbutton.iconbutton-primary.button-home-search{
        max-width: 100%;
        height: 60px;
    }
}

/* ok */
@media only screen and (min-width: 1600px) {
    .mainPage { flex-direction: row; }

    .leftCard{
        width:100%;
        margin-bottom: 0px;
    }

    .rightCard { width: 100%; }

    .moreCard{ flex-direction: row; }

    .subscriptionCard{ margin-bottom: 0px; }

    .n4mp-iconbutton.iconbutton-primary.button-home-search{
        max-width: 100%;
        height: 60px;
    }
}


/****************** OK *******************/






/**** VERIFICAR ****/

.mainPage .tires_retailers{
    display: flex;
    flex-direction: row;
    min-width: 50%;
}

.mainPage .content-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 40px 0;
}







.mainPage .input_ref{
    background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-eaeaea);
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    opacity: 1;
}





.mainPage .white-search-content .search-content .main-search-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}
.mainPage .pneus{
    flex-grow: 1;
}





.mainPage .subscription-content .subscription-button{
    padding: 3rem;
    flex-grow: 1;
}







.mainPage .tires_retailers .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 50px 0;
    /*min-width: 45%;*/

}


.mainPage .contentBox {
    margin-left: 0;
}
