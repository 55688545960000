.input-search{
    display: flex;
    flex-direction: column;
}

.input-title{
    font: normal normal 600 14px/19px Open Sans;
    color: #575757;
}

.input-ref{
    font: normal normal normal 14px/19px Open Sans;
    color: #9F9F9F;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 35px;
    padding: 20px;
}

.actions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.button-home-search{
    height: 60px;
}

/****************** OK *******************/