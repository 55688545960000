input.n4mp-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    text-align: left;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #9F9F9F;
    border: none;
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
}

input.n4mp-input:active,
input.n4mp-input:focus-visible,
input.n4mp-input:hover{
    outline-color: #575757;
}

.n4mp-input.filter-input.error{
    border-radius: 8px;
    border: 1px solid brown;
}

.input-container{
    display: flex;
    flex-direction: column;
}