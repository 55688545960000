.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

input{ padding: 0 }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*##########################*/
:root{
  --color-white:#ffffff;
  --color-light-green:#d5dfdf;
  --color-dark-green:#425c5a;

  --color-darker-green:#3c5654;
  --color-gold:#c89c32;
  --color-dark-gold:#ad7c08;
  --color-darker-gold:#8a6308;
  --color-gray:#5a665e;
  --color-green:#4c6c64;



  --color-blue:#1e90ff;
  /* --color-light-blue: #6fc0ff; */
  --color-light-blue: #b3deff;
  --color-dark-blue: #0063cb;

  --color-primary:var(--color-green);
  --font-size-default: 1.375rem;
  --font-size-Title: 1.5625rem;

  --unnamed-color-57575780: #575757;
  --unnamed-color-ff8686: #FF8686;
  --unnamed-color-91d391: #91D391;
  --unnamed-color-9f9f9f: #9F9F9F;
  --unnamed-color-fabc75: #FABC75;
  --unnamed-color-292929: #292929;
  --unnamed-color-ffffff: #FFFFFF;
  --unnamed-color-eaeaea: #EAEAEA;
  --unnamed-color-575757: #575757;
  --unnamed-color-f7f7f7: #F7F7F7;

  /* Font/text values */
  --unnamed-font-family-open-sans: Open Sans;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-600: 600px;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-22: 22px;
  --unnamed-font-size-27: 27px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-17: 17px;
  --unnamed-line-spacing-19: 19px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-37: 37px;
}

.main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.standard-layout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
}

.standard-layout .side-standard{
  min-width: 270px;
}

.standard-layout .main-standard {
  flex-direction: column;
  /* background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat padding-box; */
  background: #f0f0f0 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 0 70px 70px 70px;
  overflow: scroll;
}

.main-standard {
  flex-grow: 1;
  display: flex;
}

@media only screen and (max-width: 992px) {
    /* TESTE : Talvez encurtar sidebar para icons apenas */
    .standard-layout .side-standard{
        min-width: 70px;
        width: 70px;
    }
}