.n4mp-iconbutton{
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0 20px;
}

.btn-icon{
    display: flex;
    align-items: center;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.btn-text{ }

    /* SVG */
    .iconbutton-primary .btn-icon{ fill:#FFFFFF; }

    .iconbutton-secondary .btn-icon{ fill:#575757; }
    
    /* Type */
    .n4mp-iconbutton.iconbutton-primary{
        background: #575757 0% 0% no-repeat padding-box;
        border: none;
        color: #ffffff;
    }
    .n4mp-iconbutton.iconbutton-secondary{
        background:transparent;
        border: 1px solid #8c8b8b;
    }

    /* Hover */
    .n4mp-iconbutton.iconbutton-primary:hover{
        background: #272727 0% 0% no-repeat padding-box;
    }
    .n4mp-iconbutton.iconbutton-secondary:hover{
        background: #575757 0% 0% no-repeat padding-box;
        color: #ffffff;
    }
    .iconbutton-secondary:hover .btn-icon{
        fill:#FFFFFF;
    }

    /* Circular Progress */
    .n4mp-iconbutton.iconbutton-primary .MuiCircularProgress-colorPrimary {
        color: #FFFFFF !important;
    }   
    .n4mp-iconbutton.iconbutton-secondary .MuiCircularProgress-colorPrimary {
        color: #575757 !important;
    }
    .n4mp-iconbutton-overlay{
        border-radius: inherit;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top:0;
        bottom: 0;
        left:0;
        right:0;
        background-color: inherit;
        cursor: default;
    }

.n4mp-iconbutton-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn-green{
    font: normal normal bold 14px/19px Open Sans !important;
    background: #91D391 0% 0% no-repeat padding-box !important;
    border-radius: 8px;
    height: 50px;
}
.btn-green:hover{
    background: #87C387 0% 0% no-repeat padding-box !important;
}

.btn-gray{
    font: normal normal bold 14px/19px Open Sans !important;
    background: #575757 0% 0% no-repeat padding-box !important;
    border-radius: 8px;
    height: 50px;
}
.btn-gray:hover{
    background: #272727 0% 0% no-repeat padding-box !important;
}

.btn-red{
    font: normal normal bold 14px/19px Open Sans !important;
    background: #FF8686 0% 0% no-repeat padding-box !important;
    border-radius: 8px;
    height: 50px;
}
.btn-red:hover{
    background: #EB7C7C 0% 0% no-repeat padding-box !important;
}

/****************** OK *******************/