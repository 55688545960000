.contentBox{
    margin-bottom: 50px;
}

.contentBox-content{
    padding: 1.25rem 0.5rem;
}

.contentBox-fullHeight{
    min-height: 100%;
}

.contentBox-Transparent{
    border-radius: 5px;
    border: 2px solid;
    background-color: var(--color-blue);
    color: #fff;
    border-color: var(--color-blue);
}

.contentBox-Gold{
    background: transparent linear-gradient(140deg, #F5AB56 0%, #FFDB89 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
}

.contentBox-White{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;

}