.inputAutoComplete-container{
    position: relative;
    flex-grow: 1;
    width: 100%;
}
.inputAutoComplete{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.inputAutoComplete-group{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-color: white;
    cursor: pointer;
    color: #9F9F9F;
}

.inputAutoComplete-group .inputAutoComplete-text{
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-radius: 8px;
    border: none;
    font: normal normal normal 14px/19px Open Sans;
    color:#9F9F9F;
    padding: 15px;
    width: 100%;
}
.inputAutoComplete-group .inputAutoComplete-text:active,
.inputAutoComplete-group .inputAutoComplete-text:focus-visible{
    outline-color: #575757;
}


.inputAutoComplete-dropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: min-content;
    border-radius: 8px;
    border: 1px solid #575757;
    background-color: #FFFFFF;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: scroll;
    z-index: 1;
    top: 49px;
 
}

.inputAutoComplete-dropdown .inputAutoComplete-option{
    padding: 7px 15px;
    display: flex;
    align-items: center;
}
.inputAutoComplete-dropdown .inputAutoComplete-option:hover{
    background-color: #EAEAEA;
    cursor: pointer;
}
