.retailerForm{ padding: 20px; }

.separator-provider{
    border-bottom: solid 1px #EAEAEA;
    margin: 40px 0 30px 0;
}

.retailerForm-data-container{ margin-bottom: 20px; }

.data-title{
    font: normal normal 600 14px/19px Open Sans;
    color: #575757;
    padding-bottom: 10px;
}

.select-retailer select {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    font: normal normal 600 12px/17px Open Sans;
    color: #9F9F9F;
    padding: 10px;
    width: 335px;
    height: 50px;
}

.input-info{
    width: 315px;
    height: 50px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    padding-left: 15px;
}

.retailerForm-button{
    margin-top: 40px;
    cursor: pointer;
}

        /*** ACRESCENTAR AO BOTÃO QUE ESTÁ NA PASTA DOS COMPONENTES ***/
        .btn-Green{
            background: #91D391 0% 0% no-repeat padding-box;
            border-radius: 8px;
            height: 50px;
            margin-top: 40px;
        }
        .btn-Green:hover{
            background: #87C387 0% 0% no-repeat padding-box;
        }

        .btn-Red{
            width: 158px;
            height: 50px;
            background: #FF8686 0% 0% no-repeat padding-box;
            border-radius: 8px;
            cursor: pointer;
        }
        .btn-Red:hover{
            background: #EB7C7C 0% 0% no-repeat padding-box;
        }

        .btn-Black{
            background: #575757 0% 0% no-repeat padding-box;
            border-radius: 8px;
            height: 50px;
            margin-top: 50px;
        }
        .btn-Black:hover{
            background: #272727 0% 0% no-repeat padding-box;
        }
        /**************************/
        
/*********** OK ******************/