.filter-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search-bar-filters{
    display: flex;
    flex-direction: row;
}

.filter-input{
    width: 100px;
}

.content-range{
    display: flex;
    flex-direction: column;
}

.error-message{
    color: brown;
    margin: 5px 0px;
}

.spacerTables{
    height: 20px;
}

.budget-header{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.content-total{
    display: flex;
    flex-direction: row;
}

.total-spacer{
    flex-grow: 1;
}

/*** RESIZES ***/
@media only screen and (min-width: 375px) {
    .filter-container{
        flex-wrap: wrap;
    }

    .n4mp-form.filter-input{
        flex-grow: 1;
        width: 100%;
    }
    .filter-container .n4mp-iconbutton {
        flex-grow: 1;
        /* min-width: 42%; */
        /* max-width: 47%; */
        height: 45px;
    }

    .filter-input-button{
        flex-grow: 1;
    }


}

@media only screen and (min-width: 790px) {
    .filter-container{
        flex-wrap: wrap;
        gap: 10px;
    }

    .n4mp-form.filter-input{
        flex-grow: 1;
        max-width: 29%;
       /* display: flex; */
    }
    .filter-container .n4mp-iconbutton {
        flex-grow: 1;
        /* min-width: 42%; */
         max-width: 100%; 
        height: 45px;
    }

    .filter-input-button{
        flex-grow: 1;
    }

}

@media only screen and (min-width: 1430px) {

    .filter-container{
        flex-wrap: wrap;
        gap: 10px;
    }

    .n4mp-form.filter-input{
        /* min-width: 24%; */
        max-width: 30%;
    }
    .filter-container .n4mp-iconbutton {
        flex-grow: 1;
        /* min-width: unset;
        max-width: 32.6%; */
        height: 45px;
    }
}

@media only screen and (min-width: 1600px) {

    .filter-container{
        flex-wrap: wrap;
        gap: 10px;
    }
    .n4mp-form.filter-input{
        /* min-width: 13.1%; */
        max-width: 20%;
    }

    .filter-container .n4mp-iconbutton {
        flex-grow: 1;
        /* min-width: 20.7%;
        max-width: 20.7%;
        height: 45px; */
    }


}

@media only screen and (min-width: 1880px) {

    .filter-container{
        flex-wrap: wrap;
        gap: 2px;
    }
    .n4mp-form.filter-input{
        min-width: 5%;
        max-width: 8%;
    }

    .filter-container .n4mp-iconbutton {
        flex-grow: 1;
        /* min-width: 5%;
        max-width: 5%;
        height: 45px; */
    }

    .inputAutoComplete-dropdown{
        width: fit-content;
    }
}



/********************************/




.actionButtons{
    display: flex;
    flex-direction: row;
}


.search-content{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.search-input-group{
    display: flex;
    flex-direction: row;
}

.search-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 0px;
}

.search-label{
    display:flex;
    align-items: center;
    padding: 10px;
}

.search-input{
    display:flex;
    align-items: center;
    padding: 0px 10px 10px;
}

.search-range{
    display:flex;
    align-items: flex-end;
    padding: 0px 15px 0px;
}

.search-checkBox{
    display:flex;
    align-items:  flex-end;
    padding: 0px 10px 5px;
}

.search-input .input{
    display:flex;
    align-items: center;
}

.second-navbar{
    display:flex;
    flex-direction: column;
    width: 100%;
}

.input-discount{
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}

.title{
    display:flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 50px;
    padding-top: 15px;
}



input.n4mp-input.n4mp-input-golden {
    border: #FFDB89 1px solid;
}
input.n4mp-input.n4mp-input-golden:active,
input.n4mp-input.n4mp-input-golden:focus-visible,
input.n4mp-input.n4mp-input-golden:hover{
    border-color: none;
    outline-color: #FFDB89;
}


h2.n4mp-h2{
    text-align: left;
    font: normal normal bold 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #9F9F9F;
    opacity: 1;
    margin: 30px 0px 20px 0px;
}

h2.n4mp-h2.margin-back {
    margin-top: -20px;
}

.n4mp-table.n4mp-table-nopagination tbody tr:last-child,
.n4mp-table.n4mp-table-nopagination tbody tr:last-child td {
    border-bottom: none;
    opacity: 1;
}