.container-advanced-inputs{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.number-filters{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: white;
    background: #575757 0% 0% no-repeat padding-box;
}

.container-advanced{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    flex-grow: 1;
    border-radius: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    filter: blur(0px);
} 

.container-advanced:after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: black;
    opacity: 0.3;
    backdrop-filter: blur(30px);
    border-radius: 15px;
    z-index: -10;
}

.container-advanced-close{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.container-advanced-title{ 
    font: normal normal 600 16px/22px Open Sans;
    color: white;
    flex-grow: 1;
}

.accordion-empty{ justify-content: center; }

.accordion-svg-spacer{ width: 20px; }

.container-advanced-svg{
    fill: #FFFFFF80;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.container-advanced-svg:hover{
    fill: white;
}



