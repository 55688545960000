.n4mp-table-header-cell{
    text-align: left;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #9F9F9F;
    opacity: 1;
    padding: 20px 10px;
    white-space: pre-line;
}

.header-content{
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
}

.svg{
    width: 15px;
    height: 15px;
    fill: #9F9F9F;
}

.svg-rotate{
    width: 15px;
    height: 15px;
    fill: #9F9F9F;
    transform: rotate(180deg);
}



