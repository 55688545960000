.subscriptionCard-container{
    background: transparent linear-gradient(140deg, #F5AB56 0%, #FFDB89 100%) 0% 0% no-repeat padding-box !important;
    padding: 50px;
}

.subscriptionCard-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.subscriptionCard-content .subscription_text{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 32px/43px var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    font: normal normal bold 32px/43px Open Sans;
    color: #FFFFFF;
}

.subscriptionCard-content .subscription_button{ flex-grow: 1; }

/****************** OK *******************/



.ctacard-container-Gold .subscription-content .subscription{
    font: normal normal bold 32px/43px Open Sans;
    color: #FFFFFF;
    padding: 3rem;
}




