.mainSettings{
    display: flex;
    flex-direction: column;
    color: #575757;
}

.settings-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profitMargin{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.save{
    width:30px;
    height:30px;
    fill: #575757;
}
.save:hover{
    cursor: pointer;
    fill: #272727;
}
/****************** OK ***************/